<template>
  <main>
    <WebOsTvLnb />
    <router-view />
  </main>
</template>

<script>
import WebOsTvLnb from "./WebOsTvLnb.vue";

export default {
  components: { WebOsTvLnb },
};
</script>
